import {getPageForSlug} from '@data/queries/page/getWebsitePage'
import getWebsiteIdFromDomain from '@data/queries/website/websiteIdFromDomain'
import websiteSSRTranslations from '@helpers/misc/websiteSSRTranslations'
import AbstractLayout from '@page-components/Layout/AbstractLayout'
import Website from '@page-components/Layout/Website'
import Page from '@page-components/Page'
import {InferGetStaticPropsType} from 'next'

export default function PagesPage(props: InferGetStaticPropsType<typeof getStaticProps>) {

  return (
    <AbstractLayout LayoutComponent={Website} >
      <Page
        pageId={props.pageId}
      />
    </AbstractLayout>
  )
}

export const getStaticProps = async context => {
  const websiteId = await getWebsiteIdFromDomain(context.params.hostname)
  const {slug: slugCtx} = context.params
  const slug = `/${Array.isArray(slugCtx) ? slugCtx.join('/') : slugCtx}`

  const page = await getPageForSlug(websiteId, slug)

  if (!page) {
    return {
      notFound: true,
      revalidate: 60 * 60 * 24
    }
  }

  return {
    props: {
      ...(await websiteSSRTranslations(context.params.hostname, ['generic', 'website', 'terms'])),
      websiteId,
      pageId: page._id,
      lastRevalidatedAt: new Date().toISOString()
    },
    revalidate: 60 * 60 * 24 // 1 day. It gets invalidated on demand when any server-side updates happen anyways.
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking'
  }
}
